import axios from 'axios';

export const Articles = async () => {
  try {
    const { data } = await axios.get(
      'https://us-central1-catioroster.cloudfunctions.net/getRedditArticles',
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
