import { Button, Fade, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Articles } from '../../io';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflowY: 'scroll',
    padding: theme.spacing(6, 0),
  },
  paper: {
    height: 200,
    backgroundColor: '#d6d1d2',
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  rightPad: {
    paddingRight: 14,
  },
  media: {
    height: 200,
    width: 300,
    borderRadius: 8,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
  },
  info: {
    flexDirection: 'column',
    maxHeight: 190,
  },
  infoText: {
    flex: 1,
    'overflow-y': 'hidden',
  },
}));

const imagesToUrl = (images) => images[0].source.url;

const ArticleToCard =
  (classes) =>
  ({ url, domain, preview: { images }, title }) =>
    (
      <Grid item xs={10} sm={10} md={10} lg={5} xl={5}>
        <Paper className={classes.paper}>
          <Grid container wrap="nowrap">
            <Grid item className={classes.rightPad}>
              <img
                className={classes.media}
                alt={domain}
                src={imagesToUrl(images)}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="flexStart"
              wrap="nowrap"
              className={classes.info}
            >
              <Grid className={classes.infoText} item>
                <Typography>{title}</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {domain}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );

const articlesToCardList = (classes) => (articles) =>
  articles.map(ArticleToCard(classes));

export const CardList = () => {
  const classes = useStyles();

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await Articles();
      setArticles(data);
    })();
  }, []);

  useEffect(() => {
    let interval = null;
    if (!isActive && seconds < 3) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else {
      setIsActive(true);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <Fade in={isActive} timeout={4000}>
      <Grid
        className={classes.root}
        container
        spacing={1}
        justifyContent="space-evenly"
      >
        {articles.length > 0 ? articlesToCardList(classes)(articles) : <div />}
      </Grid>
    </Fade>
  );
};
