import './App.css';
import { CardList } from './lib/components/CardList';
import { Header } from './lib/components/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <CardList />
    </div>
  );
}

export default App;
