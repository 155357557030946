import { AppBar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#791f26',
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    paddingLeft: 10,
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

export const Header = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.header}>
      <Typography variant="h5" className={classes.title}>
        GQP Watch
      </Typography>
    </AppBar>
  );
};
